import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "~/utils/theme-provider";
import BaseChart from "./BaseChart";
import { miniChartConfig } from "./chartConfig";
import type { IMatchStats, ITeam } from "~/utils/api.interfaces.enums";

type Props = {
  activeTeam?: ITeam;
  matches?: IMatchStats[];
  showXLabels?: boolean;
};

export default memo(function ChartMiniEngagement({ matches }: Props) {
  const { t } = useTranslation();
  const [theme] = useTheme();

  const sortedMatches = useMemo(
    () =>
      [...(matches || [])].sort(
        (a, b) =>
          new Date(a.scheduledAtUtc).getTime() -
          new Date(b.scheduledAtUtc).getTime()
      ),
    [matches]
  );

  const chartData = useMemo(
    () => ({
      labels: sortedMatches.map((m) =>
        new Date(m.scheduledAtUtc).toLocaleDateString()
      ),
      datasets: [
        {
          label: t("statsPages.graphTitleEngagement"),
          data: sortedMatches.map((m) => m.totalCheers + m.totalComments),
          fill: true,
          borderColor: "#D7503D",
          backgroundColor: "rgba(215, 80, 61, 0.1)",
          tension: 0, // Changed from 0.4 to 0
          pointRadius: 0,
        },
      ],
    }),
    [sortedMatches, t]
  );

  const options = useMemo(
    () => ({
      ...miniChartConfig(theme),
    }),
    [theme]
  );

  return (
    <div className="w-full text-gray-900">
      <BaseChart
        type="line"
        data={chartData}
        options={options}
        height={100}
        width="100%"
      />
    </div>
  );
});
