import { href, Link } from "react-router";

import { useTranslation } from "react-i18next";
import { IMatchStats, ITeam, ITotals } from "~/utils/api.interfaces.enums";
import { MixPanel } from "~/utils/MixPanel";
import ChartGoalDifference from "~/components/charts/ChartGoalDifference";

export default function GoalDifference({
  matches,
  totals,
  activeTeam,
}: {
  matches: IMatchStats[];
  activeTeam?: ITeam;
  totals?: ITotals;
}) {
  const { t, ready, i18n } = useTranslation("translation");

  return (
    <>
      <div className="card h-full">
        <div className="card-body">
          <h2 className="font-bold text-base lg:text-2xl pb-0">
            {" "}
            {t("statsPages.titleGoalDifference")}
          </h2>
          <h3 className="text-sm font-normal text-gray-400 dark:text-gray-500">
            {t("statsPages.totalScoredConceded", {
              totalScored: totals?.totalScored,
              totalConceded: totals?.totalConceded,
            })}
          </h3>
          {matches?.length > 0 && (
            <h4 className="text-3xl lg:text-5xl font-bold">
              {totals?.goalDifference !== undefined && totals.goalDifference > 0
                ? "+"
                : ""}
              {totals?.goalDifference}
            </h4>
          )}
          <ChartGoalDifference
            activeTeam={activeTeam}
            matches={matches}
            limitMatches={true}
          />

          {matches?.length > 0 && (
            <Link
              to={href(`/activities/trends/goal-difference`)}
              className="btn btn-default btn-outline"
              onClick={(event) => {
                MixPanel.track(
                  "goalsScoredDetail_teamStats_tap_button_moreInfo"
                );
              }}
            >
              {t("statsPages.buttonMoreInfo")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                className="h-2 w-2 ml-auto"
                viewBox="0 0 8 12"
              >
                <path
                  fill="currentColor"
                  d="m1.686.122 6.132 5.46a.53.53 0 0 1 0 .8l-6.132 5.46a.558.558 0 0 1-.777 0l-.748-.8a.54.54 0 0 1 0-.77l4.367-3.89a.53.53 0 0 0 0-.8L.201 1.732a.54.54 0 0 1 0-.77l.748-.8a.558.558 0 0 1 .737-.04Z"
                />
              </svg>
            </Link>
          )}
        </div>
      </div>
    </>
  );
}
