import { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "~/utils/theme-provider";
import BaseChart from "./BaseChart";
import { CHART_COLORS, miniChartConfig } from "./chartConfig";
import type { IMatchStats, ITeam } from "~/utils/api.interfaces.enums";


/**
 * Mini spectator chart component
 * Displays a small line chart showing spectator counts over time
 */

type Props = {
  activeTeam?: ITeam;
  matches?: IMatchStats[];
  showXLabels?: boolean;
};

export default memo(function ChartMiniSpectator({ matches }: Props) {
  const { t } = useTranslation();
  const [theme] = useTheme();

  // Sort matches by date and memoize result
  const sortedMatches = useMemo(
    () =>
      [...(matches || [])].sort(
        (a, b) =>
          new Date(a.scheduledAtUtc).getTime() -
          new Date(b.scheduledAtUtc).getTime()
      ),
    [matches]
  );

  // Prepare chart data with memoization
  const chartData = useMemo(
    () => ({
      labels: sortedMatches.map((m) =>
        new Date(m.scheduledAtUtc).toLocaleDateString()
      ),
      datasets: [
        {
          label: t("statsPages.graphTitleSpectators"),
          data: sortedMatches.map((m) => m.viewsCount),
          fill: true,
          borderColor: CHART_COLORS.accent,
          backgroundColor: `${CHART_COLORS.accent}1A`, // 10% opacity
          tension: 0,
          pointRadius: 0,
        },
      ],
    }),
    [sortedMatches, t]
  );

  const options = useMemo(
    () => ({
      ...miniChartConfig(theme),
    }),
    [theme]
  );

  return (
    <div className="w-full text-gray-900">
      <BaseChart
        type="line"
        data={chartData}
        options={options}
        height={100}
        width="100%"
      />
    </div>
  );
});
